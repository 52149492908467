import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import Button from "../../components/button/button.component";

import { selectCurrentUser } from "../../store/user/user.selector";

import { academyRegistrationSchema } from "../../utils/schemas/schema.utlis";
import {
  checkExistingUserEmail,
  createAcademyRegistrationDocument,
} from "../../utils/firebase/firebase.utils.js";

import { toast } from 'react-toastify'

import "./academy-register-form.styles.scss";
import { setScrollToSection } from '../../store/academy-user/academy-user.slice.js';

const AcademyRegisterForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser);
  const displayName = currentUser ? currentUser.displayName : null;
  const email = currentUser ? currentUser.email : null;

  console.log(`Name >> ${displayName}, Email >> ${email}`);
  

  const onSubmit = async (values, actions) => {
    console.log('Run Code');
    
    console.log("Submitting values:", values);
    console.log("Display Name:", displayName);
    console.log("Email:", email);
    try {
      const alreadyRegistered = await checkExistingUserEmail(email);

      if (alreadyRegistered) {
        // alert("You are already registered.");
        toast.info('You are already registered!')
        return;
      }

      const response = await createAcademyRegistrationDocument(values, {
        displayName,
        email,
      });
      console.log("Registration document created successfully >>>", response);
      
      actions.resetForm();
      
      toast.success("Registration successful!");
      dispatch(setScrollToSection('book-preview'))

      navigate('/')
        
    } catch (error) {
      console.log("Error creating registration document:", error);
      toast.warning("There was an error during registration. Please make sure you've signed in.");
    }
  };

  const formik = useFormik({
    initialValues: {
      //   name: '',
      phoneNumber: "",
      //   email: '',
      address: "",
      maritalStatus: "",
      ageBracket: "",
      nameOfChurch: "",
      nameOfBusiness: "",
      mentors: "",
      coursesOfInterest: [],
      expectations: "",
      intentions: "",
    },
    validationSchema: academyRegistrationSchema,
    onSubmit,
  });

  console.log(formik);

  console.log("Initial Value: ", formik.initialValues);
  console.log("Current Value: ", formik.values);
  console.log("Errors:", formik.errors);
  

  return (
    <section className="academy-form-container contact-us py-5 px-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {currentUser ? (
              <div className="section-heading">
                <h2>Registration Form!</h2>
                <p>Welcome, {displayName}</p> <br />
                <span>Register for the Esther's Wisdom Academy Here!</span>
              </div>
            ) : (
              <div className="section-heading">
                <h2>Registration Form!</h2>
                <span>
                  Women of Impact School for Development of Ministries.
                </span>
              </div>
            )}

            <form action="" onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* <div className="col-lg-6 pb-4">
                                    <fieldset>
                                        <input 
                                            name="name" 
                                            type="text" 
                                            placeholder="Your name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                            className={formik.touched.name && formik.errors.name ? "input-error" : ""}
                                        />
                                    </fieldset>

                                    <div className="error">
                                        {formik.touched.name && formik.errors.name}
                                    </div>

                                </div> */}

                {/* <div className="col-lg-6 pb-4">
                                    <fieldset>
                                        <input 
                                            name="email" 
                                            type="text" 
                                            placeholder="Your email" 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            className={formik.touched.email && formik.errors.email ? "input-error" : ""}
                                        />
                                    </fieldset>

                                    <div className="error">
                                        {formik.touched.email && formik.errors.email}
                                    </div>
                                </div> */}

                <div className="col-lg-6 pb-4">
                  <fieldset>
                    <input
                      name="phoneNumber"
                      type="number"
                      placeholder="Your phone number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      className={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.phoneNumber && formik.errors.phoneNumber}
                  </div>
                </div>

                <div className="col-lg-6">
                  <fieldset>
                    <input
                      name="address"
                      type="text"
                      placeholder="Your address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className={
                        formik.touched.address && formik.errors.address
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.address && formik.errors.address}
                  </div>
                </div>

                <div className="col-lg-12">
                  <fieldset className="d-inline-flex">
                    <label className="m-2">Marital Status:</label>
                    <div className="radio-group">
                      {["Single", "Married", "Divorced", "Widow"].map(
                        (status) => (
                          <label key={status}>
                            <input
                              type="radio"
                              name="maritalStatus"
                              value={status}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.maritalStatus &&
                                formik.errors.maritalStatus
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {status}
                          </label>
                        )
                      )}
                    </div>
                  </fieldset>

                  <div className="error">
                    {formik.touched.maritalStatus &&
                      formik.errors.maritalStatus}
                  </div>
                </div>

                <div className="col-lg-12">
                  <fieldset className="d-inline-flex">
                    <label className="m-2">Age Bracket:</label>
                    <div className="radio-group">
                      {[
                        "15 - 25",
                        "26 - 35",
                        "36 - 45",
                        "46 - 55",
                        "56 - 65",
                        "66 - 75",
                      ].map((age) => (
                        <label key={age}>
                          <input
                            type="radio"
                            name="ageBracket"
                            value={age}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.ageBracket === age}
                          />
                          <div className='px-auto'>{age}</div>
                        </label>
                      ))}
                    </div>
                  </fieldset>

                  <div className="error">
                    {formik.touched.ageBracket && formik.errors.ageBracket}
                  </div>
                </div>

                <div className="col-lg-12 pb-4">
                  <fieldset>
                    <input
                      name="nameOfChurch"
                      type="text"
                      placeholder="Number of Church/ Ministry"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.nameOfChurch}
                      className={
                        formik.touched.nameOfChurch &&
                        formik.errors.nameOfChurch
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.nameOfChurch && formik.errors.nameOfChurch}
                  </div>
                </div>

                <div className="col-lg-12 pb-4">
                  <fieldset>
                    <input
                      name="nameOfBusiness"
                      type="text"
                      placeholder="Name of Business"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.nameOfBusiness}
                      className={
                        formik.touched.nameOfBusiness &&
                        formik.errors.nameOfBusiness
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.nameOfBusiness &&
                      formik.errors.nameOfBusiness}
                  </div>
                </div>

                <div className="col-lg-12 pb-4">
                  <fieldset>
                    <input
                      name="mentors"
                      type="text"
                      placeholder="Who are your mentors"
                      value={formik.values.mentors}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.mentors && formik.errors.mentors
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.mentors && formik.errors.mentors}
                  </div>
                </div>

                <div className="col-lg-12">
                  <label>Tick one or more of your major courses</label> <br />
                  <label>Course(s) of Interest:</label>
                  <div className="checkbox-group">
                    {[
                      "Effective Prayer Ministry",
                      "Effective Pastoring and Mentoring",
                      "Effective Leadership",
                      "The Great Commission",
                      "Children and Youth Ministry",
                      "Marriage Mentoring",
                      "Ministerial Help",
                      "Ministry of Help",
                      "Teaching Ministry/Effective Communication",
                      "Healing and Deliverance Ministry",
                      "The Creative Writing Ministry",
                      "Strategic Music Ministry",
                    ].map((course) => (
                      <label key={course}>
                        <input
                          type="checkbox"
                          name="coursesOfInterest"
                          value={course}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.coursesOfInterest.includes(
                            course
                          )}
                          className={
                            formik.touched.coursesOfInterest &&
                            formik.errors.coursesOfInterest
                              ? "input-error"
                              : ""
                          }
                        />
                        {course}
                      </label>
                    ))}
                  </div>
                  <div className="error">
                    {formik.touched.coursesOfInterest &&
                      formik.errors.coursesOfInterest}
                  </div>
                </div>

                <div className="col-lg-12">
                  <fieldset>
                    <textarea
                      name="expectations"
                      rows="6"
                      id="message"
                      placeholder="What are your expectations for this school?"
                      value={formik.values.expectations}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.expectations &&
                        formik.errors.expectations
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.expectations && formik.errors.expectations}
                  </div>
                </div>

                <div className="col-lg-12">
                  <fieldset>
                    <textarea
                      name="intentions"
                      rows="6"
                      id="message"
                      placeholder="What do you intend to do with the knowledge from the course?"
                      value={formik.values.intentions}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.intentions && formik.errors.intentions
                          ? "input-error"
                          : ""
                      }
                    />
                  </fieldset>

                  <div className="error">
                    {formik.touched.intentions && formik.errors.intentions}
                  </div>
                </div>

                <div className="col-lg-12">
                  <fieldset />
                  <Button type="submit">
                    <i className="fa fa-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcademyRegisterForm;
