import "./profile-service.styles.scss";

const ProfileSerice = () => {
  const services = [
    { id: 1, title: "Service 1", image: "https://via.placeholder.com/250" },
    { id: 2, title: "Service 2", image: "https://via.placeholder.com/250" },
  ];

  return (
    <div className="profile-services">
      <h2>What I Do</h2>
      <div className="main-service">
        {services.map((service) => (
          <div key={service.id} className="service-item">
            <img src={service.image} alt={service.title} />
            <p>{service.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileSerice;
