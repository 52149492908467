import "./profile-picture.styles.scss";

const ProfilePicture = () => {
    return (
        <div className="profile-picture">
            <img src="https://via.placeholder.com/150" alt="Profile" />
        </div>
    )
}

export default ProfilePicture