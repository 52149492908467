import React, { useState } from "react";
import "./profile-courses.styles.scss";

const ProfileCourses = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const courses = [
    { id: 1, title: "Course 1", description: "Details about Course 1" },
    { id: 2, title: "Course 2", description: "Details about Course 2" },
    { id: 3, title: "Course 3", description: "Details about Course 3" },
  ];

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="courses">
      <h2>Courses Offered</h2>
      {courses.map((course, index) => (
        <div key={course.id} className="dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown(index)}
          >
            {course.title}
          </button>
          {activeIndex === index && (
            <div className="dropdown-content">{course.description}</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProfileCourses;
