import ProfileCourses from "../../components/profile-courses/profile-courses.component"
import ProfileIntro from "../../components/profile-introduction/profile-intro.component"
import ProfilePicture from "../../components/profile-picture/profile-picture.component"
import ProfileSerice from "../../components/profile-service/profile-service.component"

import "./about-profile.styles.scss"

const AboutProfile = () => {
    return (
        <div className="profile-container">
            <ProfilePicture />
            <ProfileIntro />
            <ProfileSerice />
            <ProfileCourses />
        </div>
    )
}

export default AboutProfile