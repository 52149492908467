import "./profile-intro.styles.scss";

const ProfileIntro = () => {
    return (
        <div className="profile-intro">
            <h1>About Me</h1>
            <p>
                Hello! I'm [Client's Name]. I specialize in [Client's Profession]. My
                mission is to help individuals achieve [goals/vision].
            </p>
        </div>
    )
}

export default ProfileIntro