import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

import Layout from "./components/layout/layout.component";
import Home from "./routes/home/home.component";
import Blog from "./routes/blog/blog.component";
import BlogView from "./routes/blog-view/blog-view.component";
import Contact from "./routes/contact/contact.component";
import SignIn from "./routes/sign-in/sign-in.component";
import SignUp from "./routes/sign-up/sign-up.component";
import Admin from "./routes/admin/admin.component";
import PrivateRoute from "./components/private-route/private-route.component";
import AcademyRegisterForm from "./routes/academy-register-form/academy-register-form.component";

import { setCurrentUser } from "./store/user/user.slice";

import {
  createUserDocumentFromAuth,
  onAuthStateChangedListener,
} from "./utils/firebase/firebase.utils";

import "./App.css";
import { toast } from "react-toastify";
import AboutProfile from "./routes/about-profile/about-profile.component";

const App = () => {
  const [toastMessage, setToastMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (toastMessage) {
      toast.info(toastMessage);
      setToastMessage(""); // Reset message to prevent repeat toasts
    }
  }, [toastMessage]);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }
      console.log("SET CURRENT USER >>>", user);

      dispatch(setCurrentUser(user));
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogView />} />
          <Route
            path="academy-register"
            element={
              <PrivateRoute setToastMessage={setToastMessage}>
                <AcademyRegisterForm />
              </PrivateRoute>
            }
          />
          <Route path="about-profile" element={<AboutProfile />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
